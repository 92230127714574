import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Core Features</span>
               Of Our Decentralized Exchange Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">AMM - Automated Market Maker</h4>
                <p className="pharagraph">Our team utilizes smart contracts to set asset prices algorithmically. This enables continuous and seamless trading by automating price adjustments based on supply and demand. 
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/amm-automated-market-maker.png" alt="AMM - Automated Market Maker image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/liquidity-pools.png" alt="Liquidity Pools image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Liquidity Pools</h4>
                <p className="pharagraph">The platform we create ensures ample liquidity for trading. It reduces slippage and provides a consistent and deep market, ensuring smoother transactions across the board.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-left" >
                <h4 className="heading-h4">Swapping Mechanism</h4>
                <p className="pharagraph">Our software is the first ever project of its kind to be hosted on the Binance Smart Chain to swap BSC-based tokens with supported wallets such as Binance Chain Wallet, Metamask and Wallet Connect. </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/swapping-mechanism.png" alt="Swapping Mechanism image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
          <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/stunning-user-interface.png" alt="Stunning user interface image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Stunning user interface</h4>
                <p className="pharagraph">Our system will allow you to create a stunning user experience to keep your users coming back to the exchange not just for earning points, but also for an awesome experience.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Farming</h4>
                <p className="pharagraph">Our platform enables yield farming by providing liquidity or participating in specific protocols. It allows your users to maximize their ROI with various reward mechanisms.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/security.png" alt="Security image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures