import React from 'react'
// import more from "../../images/defiexchange/defi-exchange.svg"



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Overview of </span>
              Decentralized Exchange Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
              <p className="pharagraph">Decentralized Exchange development involves building an online platform that enables transactions without intermediaries. This system facilitates direct trades between users buying and selling cryptocurrencies. In contrast to centralized exchanges, it allows users to retain complete ownership and control over their funds.
              </p>
              <p className="pharagraph mb-0">At Coinsclone, we lead the way in Decentralized Exchange (DEX) Development, offering innovative solutions that transform digital trading. Our DEX platforms provide top-tier security through blockchain technology, giving users control of their private keys and reducing the risk of large-scale breaches. Major features of our DEX include Liquidity Pools, Farming, Staking, Token Swapping, and many more. We prioritize privacy with minimal data requirements, ensuring user confidentiality and protection against potential breaches. Our solutions are globally accessible, breaking geographical barriers and offering worldwide trading opportunities.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/defi-exchange.svg" alt="DeFi Exchange image1" />
            </div>
          </div>
        </div>
      </section>  
    )
  }
}

export default Whatis