import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How does a Decentralized Exchange make money?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">DEX platforms typically earn revenue through transaction fees, which are a small percentage of each trade. Additional income can come from listing fees and liquidity provision incentives.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How much does it cost to create a Decentralized Exchange?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost to create a decentralized exchange varies widely based on complexity and features but generally ranges from $30,000 to $150,000. Customization, security measures, and integration can influence the final cost.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                What are the Best Decentralized Exchanges as business models for Beginners?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The best Decentralized Exchanges for beginners are Uniswap and PancakeSwap, known for their user-friendly interfaces and low transaction fees. You can also create a Decentralized Exchange Platform like these popular platforms using our White Label Decentralized Exchange Solution.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                  Do you develop just the back-end for the Decentralized Exchange or do you also develop the interface?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">When you hire us as your Decentralized Exchange development company we deliver you the complete solution including designing, creating an interface and building the back end.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How much time does it take to deploy a fully functional Decentralized Exchange?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Deploying a fully functional decentralized exchange usually takes between 3 to 6 months, depending on the project's scope, complexity, and the level of customization required.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                Why should I consider developing a decentralized exchange for my business?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Developing a decentralized exchange can enhance security, reduce transaction fees, and offer global accessibility. It also provides a competitive edge by embracing innovative technology and catering to the growing demand for decentralized finance solutions.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection