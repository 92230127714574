import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">What Makes Coinsclone </span>The Best</span> Decentralized Exchange Development Company?</h3>
            <p className="pharagraph head">
            </p>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/one-stop-solution.svg" alt="One-stop solution image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  One-stop solution
                </h3>
                <p className="pharagraph">
                Coinsclone provides a seamless, all-in-one service for DEX development. From initial analysis through design, development, and deployment, we handle every aspect to ensure a smooth and efficient process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/world-class-experience.svg" alt="World-class experience image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  World-class experience
                </h3>
                <p className="pharagraph">
                Our team boasts extensive experience in creating, deploying, and maintaining crypto exchanges and blockchain projects. With a track record of successful global projects, we bring unparalleled expertise and innovation to each development.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/whitelabel-script.svg" alt="White Label Decentralized Exchange Script" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                White Label Decentralized Exchange Script
                </h3>
                <p className="pharagraph">
                Our White Label DEX Script provides you with full control to customize and modify the code according to your specific needs. And also you can align the platform as per your vision and business goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/custom-features.svg" alt="Custom features image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Custom features
                </h3>
                <p className="pharagraph">
                If you envision unique functionalities beyond the standard offerings of mainstream DEXs, we are eager to develop custom features tailored specifically to your needs. Your innovation is our mission.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content lastblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/transparency.svg" alt="Transparency image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Transparency
                </h3>
                <p className="pharagraph">
                At Coinsclone, transparency is key. We provide clear, upfront estimates regarding pricing and timelines. We are committed to adhering to our agreed schedules, ensuring you’re never caught off guard.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/customization-and-branding.svg" alt="Customization and branding image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Customization and branding
                </h3>
                <p className="pharagraph">
                Your DEX will be customized and branded to align perfectly with your business identity. We ensure your platform stands out as a unique product, reflecting your vision and brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose