import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/defiexchange/Banner"
import Whatis from "../components/defiexchange/Whatis"
import WhyShould from "../components/defiexchange/WhyShould"
import TopFeatures from "../components/defiexchange/TopFeatures"
import HowOur from "../components/defiexchange/HowOur"
import CoreFeatures from "../components/defiexchange/CoreFeatures"
import SecurityofOur from "../components/defiexchange/SecurityofOur"
import UsecaseOf from "../components/defiexchange/UsecaseOf"
import WhyChoose from "../components/defiexchange/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import PortfolioSection from "../components/whitelabelpage/Portfolio"
import DevApproach from "../components/defiexchange/DevApproach"
import TechStack from "../components/defiexchange/TechStack"
import FaqSection from "../components/defiexchange/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"

// import Header from "../components/header"




const DeFiexchange = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Decentralized Exchange Development Company- Coinsclone</title>                     
        <meta name="description" content="Coinsclone is a best Decentralized Exchange Development Company. We offer splendid Decentralized Exchange services with impeccable outcomes." />
        <meta name="keywords" content="Decentralized Exchange Development Company, Decentralized Exchange Development services, White Label DEX development, DEX development company, White Label decentralized exchange development company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Decentralized Exchange Development Company- Coinsclone" />
        <meta property="og:description" content="Coinsclone is a best Decentralized Exchange Development Company. We offer splendid Decentralized Exchange services with impeccable outcomes." />
        <meta property="og:url" content="https://www.coinsclone.com/decentralized-exchange-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defi-exchange-development-company.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a best Decentralized Exchange Development Company. We offer splendid Decentralized Exchange services with impeccable outcomes." />
        <meta name="twitter:title" content="Decentralized Exchange Development Company- Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defi-exchange-development-company.png" />
        <link rel="canonical" href="https://www.coinsclone.com/decentralized-exchange-development-company/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Decentralized Exchange Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default DeFiexchange
